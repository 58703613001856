// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["K44XzL9el", "iC5Ary41s"];

const serializationHash = "framer-vVjJ4"

const variantClassNames = {iC5Ary41s: "framer-v-q025rl", K44XzL9el: "framer-v-1qqyrmb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Main: "K44XzL9el", Secondary: "iC5Ary41s"}

const getProps = ({height, id, link, width, ...props}) => { return {...props, j5O95N1Qq: link ?? props.j5O95N1Qq, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "K44XzL9el"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, j5O95N1Qq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "K44XzL9el", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={j5O95N1Qq}><Image {...restProps} animate={variants} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 51, intrinsicWidth: 130, pixelHeight: 51, pixelWidth: 130, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/3NrpyMNK4bNbGhZj6K27zFyAE.svg"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1qqyrmb", className, classNames)} framer-c618nx`} data-framer-name={"Main"} initial={variant} layoutDependency={layoutDependency} layoutId={"K44XzL9el"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({iC5Ary41s: {"data-framer-name": "Secondary", background: {alt: "", fit: "fit", intrinsicHeight: 39, intrinsicWidth: 101, pixelHeight: 39, pixelWidth: 101, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/OHOt2hGHnIzZMThiH6kHCjOA.svg"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-vVjJ4[data-border=\"true\"]::after, .framer-vVjJ4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vVjJ4.framer-c618nx, .framer-vVjJ4 .framer-c618nx { display: block; }", ".framer-vVjJ4.framer-1qqyrmb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: 50px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 130px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vVjJ4.framer-1qqyrmb { gap: 0px; } .framer-vVjJ4.framer-1qqyrmb > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-vVjJ4.framer-1qqyrmb > :first-child { margin-left: 0px; } .framer-vVjJ4.framer-1qqyrmb > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 130
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"iC5Ary41s":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"j5O95N1Qq":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerw3LpVOQfy: React.ComponentType<Props> = withCSS(Component, css, "framer-vVjJ4") as typeof Component;
export default Framerw3LpVOQfy;

Framerw3LpVOQfy.displayName = "Logo";

Framerw3LpVOQfy.defaultProps = {height: 50, width: 130};

addPropertyControls(Framerw3LpVOQfy, {variant: {options: ["K44XzL9el", "iC5Ary41s"], optionTitles: ["Main", "Secondary"], title: "Variant", type: ControlType.Enum}, j5O95N1Qq: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerw3LpVOQfy, [])